// import Vue from 'vue'
// import VueRouter from 'vue-router'
import routerPC from '../router/PC'
import routerMobile from '../router/Mobile'

let routes = []
const flag = navigator.userAgent.match(/(phone|pad|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows phone)/i)
if (flag) {
  routes = routerMobile
} else {
  routes = routerPC
}

export default routes
