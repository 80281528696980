import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/index'
  },
  {
    path: '/index',
    component: () => import('@/views/PC/home')
  },
  {
    path: '/platform',
    component: () => import('@/views/PC/platform/index')
  },
  {
    path: '/formulate',
    component: () => import('@/views/PC/formulate/index')
  },
  {
    path: '/about',
    component: () => import('@/views/PC/about/index')
  },
  {
    path: '/connect',
    component: () => import('@/views/PC/connect/index')
  },
  {
    path: '/school',
    component: () => import('@/views/PC/school/index')
  },
  {
    path: '/service',
    component: () => import('@/views/PC/service/index')
  },
  {
    path: '/enter',
    component: () => import('@/views/PC/enter/index')
  },
  {
    path: '/isenter',
    component: () => import('@/views/PC/isEnter/index')
  },
  {
    path: '/select',
    component: () => import('@/views/PC/isEnter/select.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
