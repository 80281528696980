import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/index'
  },
  {
    path: '/index',
    component: () => import('@/views/Moblie/home')
  },
  {
    path: '/platform',
    component: () => import('@/views/Moblie/platform/index')
  },
  {
    path: '/formulate',
    component: () => import('@/views/Moblie/formulate/index')
  },
  {
    path: '/about',
    component: () => import('@/views/Moblie/about/index')
  },
  {
    path: '/connect',
    component: () => import('@/views/Moblie/connect/index')
  },
  {
    path: '/school',
    component: () => import('@/views/Moblie/school/index')
  },
  {
    path: '/service',
    component: () => import('@/views/Moblie/service/index')
  },
  {
    path: '/enter',
    component: () => import('@/views/Moblie/enter/index')
  },
  {
    path: '/isenter',
    component: () => import('@/views/Moblie/isEnter/index')
  },
  {
    path: '/select',
    component: () => import('@/views/Moblie/isEnter/select.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
