<template>
    <router-view></router-view>
</template>

<script>
export default {
  name: 'App',
  data () {
    return {
      Equip: ''
    }
  },
  mounted () {
    if (this._isMobile()) {
      this.Equip = 'mobile'
      // console.log(this.Equip)
      this.$router.replace('/index')
    } else {
      this.Equip = 'PC'
      // console.log(this.Equip)
      this.$router.replace('/index')
    }
  },
  methods: {
    // App.vue
    _isMobile () {
      const flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag
    }

  }
}
</script>

<style lang="less">
body{
  margin: 0;
  padding: 0;
}
</style>
